<template>
  <div v-if="isDeveloper()" class="container-fluid mt-3">
    <back-button @click="goBack" />
    <loading-panel v-if="loading"></loading-panel>
    <div v-if="!loading" class="row mt-3">
      <div class="col-xl-12 order-xl-1">
        <card>
          <div slot="header" class="row align-items-center">
            <div class="col-8">
              <h3 class="mb-0">Generate Stripe Coupons</h3>
            </div>
          </div>
          <div class="card-body">
            <form @submit.prevent="handleSubmit">
              <base-input
                label="Number of Coupons *"
                type="number"
                min="1"
                v-model="couponsNumber"
              />
              <validation-error :errors="apiValidationErrors.couponsNumber" />
              <base-input label="Secret Key *" v-model="secretKey" />
              <validation-error :errors="apiValidationErrors.secretKey" />
              <div class="my-4">
                <base-button
                  type="button"
                  class="btn btn-sm btn-primary"
                  native-type="submit"
                  :disabled="!couponsNumber || !secretKey"
                >
                  <i class="fa fa-save" /> Generate
                </base-button>
              </div>
            </form>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import formMixin from "@/mixins/form-mixin";
import BackButton from "@/components/BackButton.vue";
import ValidationError from "@/components/ValidationError.vue";
import axios from "axios";
import { exportCsv } from "@/util/export-csv";
import { LoadingPanel } from "@/components";

// Coupon code length (including prefix). Must be between 3 and 200
const CODE_LENGTH = 40;
// Optional prefix for codes
const PREFIX = "SuMo";
// List of characters to generate code from
const chars = "AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890";
// This csv file will contain all your AppSumo coupon codes
const axiosWithoutAuth = axios.create();
export default {
  layout: "DashboardLayout",
  mixins: [formMixin],
  components: {
    BackButton,
    ValidationError,
    LoadingPanel,
  },
  data() {
    return {
      couponsNumber: null,
      secretKey: null,
      loading: false,
    };
  },
  methods: {
    goBack() {
      this.$router.push({ name: "Main Dashboard" });
    },
    async handleSubmit() {
      const NB_COUPONS = this.couponsNumber;
      const fileName = `AppSumo-${NB_COUPONS}-codes.csv`;
      const exportData = [];
      this.loading = true;
      try {
        const response = await axiosWithoutAuth.post(
          "https://api.stripe.com/v1/coupons",
          this.serialize({
            name: "AppSumo Lifetime Deal",
            percent_off: 100,
            duration: "forever",
          }),
          {
            headers: {
              Authorization: "Bearer " + this.secretKey,
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        );
        const coupon = response.data;
        for (let i = 0; i < NB_COUPONS; i++) {
          const code = await this.createPromo(coupon.id);
          exportData.push({ "Coupon Codes": code });
          if (i === NB_COUPONS - 1) {
            exportCsv(exportData, fileName);
            this.loading = false;
          }
        }
      } catch (error) {
        console.log(error);
        this.loading = false;
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
      }
    },
    serialize(obj) {
      const params = [];
      for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          params.push(
            `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`
          );
        }
      }
      return params.join("&");
    },
    generateRandomCode() {
      return (
        PREFIX +
        Array.from(
          { length: CODE_LENGTH - PREFIX.length },
          (v, k) => chars[Math.floor(Math.random() * chars.length)]
        ).join("")
      );
    },
    async createPromo(coupon) {
      const code = this.generateRandomCode();
      try {
        const response = await axiosWithoutAuth.post(
          "https://api.stripe.com/v1/promotion_codes",
          this.serialize({
            coupon,
            code,
            max_redemptions: 1,
          }),
          {
            headers: {
              Authorization: "Bearer " + this.secretKey,
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        );
        return response.data.code;
      } catch (e) {
        this.loading = false;
        console.log(e);
      }
    },
  },
};
</script>
